const ROUTES = {
  HOME: '/',
  PANEL: '/panel',
  LOG_IN: '/login',
  LOG_OUT: '/panel/logout',
  REGISTER: '/register',
  REGISTER_ACCOUNT: '/register/account',
  REGISTER_ACCOUNT_SUCCESS: '/register/success',
  WELCOME_PAGE: '/welcome',
  RESET: '/reset',
  CHANGE_PASSWORD: '/resetting/reset/:token',
  EDIT_REVIEW_PAGE: '/invitation/:slug/edit/:token',
  EDIT_NPS_PAGE: '/nps/invitation/:token',
  REVIEW_CONFIRM: '/reviews/:slug/confirm/:token',
  SURVEYS: {
    PRODUCTS_REVIEW: '/invitation/:token/product/:productId/grade/:grade',
    INVITATION: '/surveys/invitation/:invitationToken',
    ANONYMOUS: '/surveys/anonymous/:surveyToken',
    PREVIEW: '/surveys/preview/:surveyToken',
    FORM: '/surveys/form/:formToken',
  },
  THANK_YOU_PAGE: '/invitation/thank-you',
  OPT_OUT_CONFIRMATION_PAGE: '/invitation/optout/:slug/:invitationToken',
  MEDIATION: '/mediation/:token',
  THANK_YOU_PAGE_PREVIEW: '/panel/api/invitation_config/:configId/thank_you_page/preview',
  PRICING: '/pricing',
  REFERENCES: '/references',
  USER_HOME_PAGE: '/find-company',
  JOBS_BOARD: '/jobs',
  CATEGORY_PAGE: '/categories',
  SURVEYS_AND_POLLS: '/surveys',
  GOOGLE: '/google',
  COLLECTING_REVIEWS: '/collecting-reviews',
  INCREASE_SALES: '/increase-sales',
  CLIENT_CONTACT: '/client-contact',
  SEARCH_RESULTS: '/search/:phrase',
  USER_REGULATIONS: '/user-regulations',
  BUSINESS_REGULATIONS: '/regulations',
  PRIVACY_POLICY: '/privacy-policy',
  FB_REVIEWS: '/fb/reviews',
  FB_REVIEWS_WITH_PAGE: '/fb/reviews/:page?',
  FB_REVIEWS_REGISTER: '/fb/install/:fbId',
  PROMOTED_REVIEWS: '/promoted/reviews/:accountSlug/:page?',
  CLIENT_SATISFACTION: '/zadowolenie-klienta/:uuid',
  CONTACT: '/contact',
  BLOG: 'https://blog.trustmate.io',
  PODCAST: 'https://platforms.trustmate.io/podcast',
  PARTNERS: '/partnership',
  PARTNERS_REGISTER: '/partners/register',
  PARTNERS_LOGIN: '/partners/login',
  REVIEWS: '/our-reviews',
  INTEGRATIONS: '/integrations',
  REVIEW_TRANSLATE: '/review-translate',
  WHY_US: '/why-us',
  SA_INFO: '/SA-info',
  BADGES: '/badges',
  MORE_SALES: '/more-sales',
  TRAFFIC: '/traffic',
  CROSSBORDER_SALES: '/crossborder-sales',
  PRODUCT_POLLS: '/product-polls',
  REVIEW_COLLECTION: '/review-collection',
  EU: '/projekty-ue',
  // Just for redirection purpuse, can be remove when clients stop using old QR product profiles
  PRODUCT_BUSINESS_CARD: '/company/:uuid/product/:productId',
  TEAM: '/our-team',
  HOTELS: '/hotels',
};

type I18nRouteType = {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
};

export const I18N_ROUTES: I18nRouteType = {
  bg: {
    CATEGORY_MAIN: '/kategorii-bg',
    CATEGORY_SUBTYPE_PAGE: '/kategorii-bg/:subtype',
    CATEGORY_PAGE: '/kategorii-bg/:subtype/:slug',
    COMPANY_PROFILE: '/otzivi/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/otzivi/:slug/:page?',
    REVIEWS: '/otzivi',
    SUBTYPES: {
      SHOPS: 'magaziny-bg',
      SERVICES: 'uslugi',
      HOTELS: 'hoteli',
      RESTAURANTS: 'restoranti',
    },
  },
  cs: {
    CATEGORY_MAIN: '/kategorie-cs',
    CATEGORY_SUBTYPE_PAGE: '/kategorie-cs/:subtype',
    CATEGORY_PAGE: '/kategorie-cs/:subtype/:slug',
    COMPANY_PROFILE: '/hodnoceni/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/hodnoceni/:slug/:page?',
    REVIEWS: '/hodnoceni',
    SUBTYPES: {
      SHOPS: 'obchody',
      SERVICES: 'sluzby',
      HOTELS: 'hotely',
      RESTAURANTS: 'restaurace',
    },
  },
  de: {
    CATEGORY_MAIN: '/kategorien',
    CATEGORY_SUBTYPE_PAGE: '/kategorien/:subtype',
    CATEGORY_PAGE: '/kategorien/:subtype/:slug',
    COMPANY_PROFILE: '/bewertungen/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/bewertungen/:slug/:page?',
    REVIEWS: '/bewertungen',
    SUBTYPES: {
      SHOPS: 'online-shops',
      SERVICES: 'unternehmen',
      HOTELS: 'hotels-de',
      RESTAURANTS: 'restaurants-de',
    },
  },
  el: {
    CATEGORY_MAIN: '/katigories',
    CATEGORY_SUBTYPE_PAGE: '/katigories/:subtype',
    CATEGORY_PAGE: '/katigories/:subtype/:slug',
    COMPANY_PROFILE: '/vathmologies/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/vathmologies/:slug/:page?',
    REVIEWS: '/vathmologies',
    SUBTYPES: {
      SHOPS: 'katastimata',
      SERVICES: 'ypiresies',
      HOTELS: 'xenodocheia',
      RESTAURANTS: 'estiatoria',
    },
  },
  en: {
    CATEGORY_MAIN: '/categories',
    CATEGORY_SUBTYPE_PAGE: '/categories/:subtype',
    CATEGORY_PAGE: '/categories/:subtype/:slug',
    COMPANY_PROFILE: '/reviews/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/reviews/:slug/:page?',
    REVIEWS: '/reviews',
    SUBTYPES: {
      SHOPS: 'shops',
      SERVICES: 'services',
      HOTELS: 'hotels',
      RESTAURANTS: 'restaurants',
    },
  },
  es: {
    CATEGORY_MAIN: '/categorias-es',
    CATEGORY_SUBTYPE_PAGE: '/categorias-es/:subtype',
    CATEGORY_PAGE: '/categorias-es/:subtype/:slug',
    COMPANY_PROFILE: '/opiniones/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/opiniones/:slug/:page?',
    REVIEWS: '/opiniones',
    SUBTYPES: {
      SHOPS: 'tienda',
      SERVICES: 'servicios',
      HOTELS: 'hoteles',
      RESTAURANTS: 'restaurantes',
    },
  },
  et: {
    CATEGORY_MAIN: '/kategooriad',
    CATEGORY_SUBTYPE_PAGE: '/kategooriad/:subtype',
    CATEGORY_PAGE: '/kategooriad/:subtype/:slug',
    COMPANY_PROFILE: '/arvustused/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/arvustused/:slug/:page?',
    REVIEWS: '/arvustused',
    SUBTYPES: {
      SHOPS: 'poed',
      SERVICES: 'teenused',
      HOTELS: 'hotellid',
      RESTAURANTS: 'restoranid',
    },
  },
  fr: {
    CATEGORY_MAIN: '/categories',
    CATEGORY_SUBTYPE_PAGE: '/categories/:subtype',
    CATEGORY_PAGE: '/categories/:subtype/:slug',
    COMPANY_PROFILE: '/avis/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/avis/:slug/:page?',
    REVIEWS: '/avis',
    SUBTYPES: {
      SHOPS: 'magasins',
      SERVICES: 'prestations-de-service',
      HOTELS: 'hotels-fr',
      RESTAURANTS: 'restaurants-fr',
    },
  },
  hr: {
    CATEGORY_MAIN: '/kategorije',
    CATEGORY_SUBTYPE_PAGE: '/kategorije/:subtype',
    CATEGORY_PAGE: '/kategorije/:subtype/:slug',
    COMPANY_PROFILE: '/recenzije/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/recenzije/:slug/:page?',
    REVIEWS: '/recenzije',
    SUBTYPES: {
      SHOPS: 'trgovine',
      SERVICES: 'usluge',
      HOTELS: 'hoteli-hr',
      RESTAURANTS: 'restorani',
    },
  },
  hu: {
    CATEGORY_MAIN: '/kategoriak',
    CATEGORY_SUBTYPE_PAGE: '/kategoriak/:subtype',
    CATEGORY_PAGE: '/kategoriak/:subtype/:slug',
    COMPANY_PROFILE: '/ertekelesek/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/ertekelesek/:slug/:page?',
    REVIEWS: '/ertekelesek',
    SUBTYPES: {
      SHOPS: 'uzletek',
      SERVICES: 'szolgaltatasok',
      HOTELS: 'hotelek',
      RESTAURANTS: 'etteremek',
    },
  },
  it: {
    CATEGORY_MAIN: '/categorie',
    CATEGORY_SUBTYPE_PAGE: '/categorie/:subtype',
    CATEGORY_PAGE: '/categorie/:subtype/:slug',
    COMPANY_PROFILE: '/recensioni/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/recensioni/:slug/:page?',
    REVIEWS: '/recensioni',
    SUBTYPES: {
      SHOPS: 'negozi',
      SERVICES: 'servizi',
      HOTELS: 'hotel',
      RESTAURANTS: 'ristoranti',
    },
  },
  lt: {
    CATEGORY_MAIN: '/kategorijos',
    CATEGORY_SUBTYPE_PAGE: '/kategorijos/:subtype',
    CATEGORY_PAGE: '/kategorijos/:subtype/:slug',
    COMPANY_PROFILE: '/atsiliepimai/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/atsiliepimai/:slug/:page?',
    REVIEWS: '/atsiliepimai',
    SUBTYPES: {
      SHOPS: 'parduotuves',
      SERVICES: 'paslaugos',
      HOTELS: 'viesbuciai',
      RESTAURANTS: 'restoranai',
    },
  },
  lv: {
    CATEGORY_MAIN: '/kategorijas',
    CATEGORY_SUBTYPE_PAGE: '/kategorijas/:subtype',
    CATEGORY_PAGE: '/kategorijas/:subtype/:slug',
    COMPANY_PROFILE: '/atsauksmes/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/atsauksmes/:slug/:page?',
    REVIEWS: '/atsauksmes',
    SUBTYPES: {
      SHOPS: 'veikali',
      SERVICES: 'pakalpojumus',
      HOTELS: 'viesnicas',
      RESTAURANTS: 'restorani-lv',
    },
  },
  nl: {
    CATEGORY_MAIN: '/categorieen',
    CATEGORY_SUBTYPE_PAGE: '/categorieen/:subtype',
    CATEGORY_PAGE: '/categorieen/:subtype/:slug',
    COMPANY_PROFILE: '/reviews-nl/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/reviews-nl/:slug/:page?',
    REVIEWS: '/reviews-nl',
    SUBTYPES: {
      SHOPS: 'winkels',
      SERVICES: 'bedrijven',
      HOTELS: 'hotels-nl',
      RESTAURANTS: 'restaurants-nl',
    },
  },
  no: {
    CATEGORY_MAIN: '/kategorier-no',
    CATEGORY_SUBTYPE_PAGE: '/kategorier-no/:subtype',
    CATEGORY_PAGE: '/kategorier-no/:subtype/:slug',
    COMPANY_PROFILE: '/anmeldelser/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/anmeldelser/:slug/:page?',
    REVIEWS: '/anmeldelser',
    SUBTYPES: {
      SHOPS: 'butikker',
      SERVICES: 'tjenester',
      HOTELS: 'hoteller-no',
      RESTAURANTS: 'restauranter-no',
    },
  },
  pl: {
    CATEGORY_MAIN: '/kategorie',
    CATEGORY_SUBTYPE_PAGE: '/kategorie/:subtype',
    CATEGORY_PAGE: '/kategorie/:subtype/:slug',
    COMPANY_PROFILE: '/opinie/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/opinie/:slug/:page?',
    REVIEWS: '/opinie',
    SUBTYPES: {
      SHOPS: 'sklepy',
      SERVICES: 'firmy',
      HOTELS: 'hotele',
      RESTAURANTS: 'restauracje',
    },
  },
  pt: {
    CATEGORY_MAIN: '/categorias-pt',
    CATEGORY_SUBTYPE_PAGE: '/categorias-pt/:subtype',
    CATEGORY_PAGE: '/categorias-pt/:subtype/:slug',
    COMPANY_PROFILE: '/opinioes/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/opinioes/:slug/:page?',
    REVIEWS: '/opinioes',
    SUBTYPES: {
      SHOPS: 'lojas',
      SERVICES: 'empresas',
      HOTELS: 'hoteis',
      RESTAURANTS: 'restaurantes',
    },
  },
  ro: {
    CATEGORY_MAIN: '/categorii',
    CATEGORY_SUBTYPE_PAGE: '/categorii/:subtype',
    CATEGORY_PAGE: '/categorii/:subtype/:slug',
    COMPANY_PROFILE: '/recenzie/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/recenzie/:slug/:page?',
    REVIEWS: '/recenzie',
    SUBTYPES: {
      SHOPS: 'magazine',
      SERVICES: 'companii',
      HOTELS: 'hoteluri',
      RESTAURANTS: 'restaurante',
    },
  },
  ru: {
    CATEGORY_MAIN: '/kategorii',
    CATEGORY_SUBTYPE_PAGE: '/kategorii/:subtype',
    CATEGORY_PAGE: '/kategorii/:subtype/:slug',
    COMPANY_PROFILE: '/otzyvy/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/otzyvy/:slug/:page?',
    REVIEWS: '/otzyvy',
    SUBTYPES: {
      SHOPS: 'magaziny',
      SERVICES: 'servisy',
      HOTELS: 'oteli',
      RESTAURANTS: 'restorany',
    },
  },
  sk: {
    CATEGORY_MAIN: '/kategorie-sk',
    CATEGORY_SUBTYPE_PAGE: '/kategorie-sk/:subtype',
    CATEGORY_PAGE: '/kategorie-sk/:subtype/:slug',
    COMPANY_PROFILE: '/hodnoteni/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/hodnoteni/:slug/:page?',
    REVIEWS: '/hodnoteni',
    SUBTYPES: {
      SHOPS: 'obchody',
      SERVICES: 'sluzby',
      HOTELS: 'hotely-sk',
      RESTAURANTS: 'restauracie',
    },
  },
  sl: {
    CATEGORY_MAIN: '/kategorije-sl',
    CATEGORY_SUBTYPE_PAGE: '/kategorije-sl/:subtype',
    CATEGORY_PAGE: '/kategorije-sl/:subtype/:slug',
    COMPANY_PROFILE: '/mnenja-in-ocene/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/mnenja-in-ocene/:slug/:page?',
    REVIEWS: '/mnenja-in-ocene',
    SUBTYPES: {
      SHOPS: 'trgovine-sl',
      SERVICES: 'storitve',
      HOTELS: 'hoteli-sl',
      RESTAURANTS: 'restavracije',
    },
  },
  sr: {
    CATEGORY_MAIN: '/kategorije-sr',
    CATEGORY_SUBTYPE_PAGE: '/kategorije-sr/:subtype',
    CATEGORY_PAGE: '/kategorije-sr/:subtype/:slug',
    COMPANY_PROFILE: '/recenzije-sr/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/recenzije-sr/:slug/:page?',
    REVIEWS: '/recenzije-sr',
    SUBTYPES: {
      SHOPS: 'prodavnice',
      SERVICES: 'usluge-sr',
      HOTELS: 'hoteli-sr',
      RESTAURANTS: 'restorani-sr',
    },
  },
  sv: {
    CATEGORY_MAIN: '/kategorier',
    CATEGORY_SUBTYPE_PAGE: '/kategorier/:subtype',
    CATEGORY_PAGE: '/kategorier/:subtype/:slug',
    COMPANY_PROFILE: '/omdomen:slug',
    COMPANY_PROFILE_WITH_PAGE: '/omdomen/:slug/:page?',
    REVIEWS: '/omdomen',
    SUBTYPES: {
      SHOPS: 'onlinebutiker',
      SERVICES: 'tjanster',
      HOTELS: 'hotell-sv',
      RESTAURANTS: 'restauranger',
    },
  },
  uk: {
    CATEGORY_MAIN: '/katehoriyi',
    CATEGORY_SUBTYPE_PAGE: '/katehoriyi/:subtype',
    CATEGORY_PAGE: '/katehoriyi/:subtype/:slug',
    COMPANY_PROFILE: '/vidhuky/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/vidhuky/:slug/:page?',
    REVIEWS: '/vidhuky',
    SUBTYPES: {
      SHOPS: 'mahazyn',
      SERVICES: 'kompaniyi',
      HOTELS: 'hoteli-uk',
      RESTAURANTS: 'restorany-uk',
    },
  },
  tr: {
    CATEGORY_MAIN: '/kategoriler',
    CATEGORY_SUBTYPE_PAGE: '/kategoriler/:subtype',
    CATEGORY_PAGE: '/kategoriler/:subtype/:slug',
    COMPANY_PROFILE: '/yorumlar/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/yorumlar/:slug/:page?',
    REVIEWS: '/yorumlar',
    SUBTYPES: {
      SHOPS: 'mağazalar',
      SERVICES: 'hizmetler',
      HOTELS: 'oteller',
      RESTAURANTS: 'restoranlar',
    },
  },
  da: {
    CATEGORY_MAIN: '/kategorier-da',
    CATEGORY_SUBTYPE_PAGE: '/kategorier-da/:subtype',
    CATEGORY_PAGE: '/kategorier-da/:subtype/:slug',
    COMPANY_PROFILE: '/anmeldelser/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/anmeldelser/:slug/:page?',
    REVIEWS: '/anmeldelser',
    SUBTYPES: {
      SHOPS: 'butikker',
      SERVICES: 'tjenester',
      HOTELS: 'hoteller',
      RESTAURANTS: 'restauranter',
    },
  },
  fi: {
    CATEGORY_MAIN: '/kategoriat',
    CATEGORY_SUBTYPE_PAGE: '/kategoriat/:subtype',
    CATEGORY_PAGE: '/kategoriat/:subtype/:slug',
    COMPANY_PROFILE: '/arvostelut/:slug',
    COMPANY_PROFILE_WITH_PAGE: '/arvostelut/:slug/:page?',
    REVIEWS: '/arvostelut',
    SUBTYPES: {
      SHOPS: 'kaupat',
      SERVICES: 'palvelut',
      HOTELS: 'hotellit',
      RESTAURANTS: 'ravintolat',
    },
  },
};

export default ROUTES;
